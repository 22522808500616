import React from "react";
import AboutUs from "../components/AboutUs"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const Policy = (props) =>{
    return(
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <AboutUs {...props} fields={props?.data} />
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(Policy);

export const query = graphql`
 fragment PolicyFragment on MarkdownRemark {
      frontmatter {
      title
      description
    }
  }
  query PolicyPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "policy-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enPolicy: markdownRemark(frontmatter: {templateKey: {eq: "en-policy"}}) {
    ...PolicyFragment
  }
  dePolicy: markdownRemark(frontmatter: {templateKey: {eq: "de-policy"}}) {
    ...PolicyFragment
  }
  esPolicy: markdownRemark(frontmatter: {templateKey: {eq: "es-policy"}}) {
    ...PolicyFragment
  }
  frPolicy: markdownRemark(frontmatter: {templateKey: {eq: "fr-policy"}}) {
    ...PolicyFragment
  }
  hePolicy: markdownRemark(frontmatter: {templateKey: {eq: "he-policy"}}) {
    ...PolicyFragment
  }
  itPolicy: markdownRemark(frontmatter: {templateKey: {eq: "it-policy"}}) {
    ...PolicyFragment
  }
  jaPolicy: markdownRemark(frontmatter: {templateKey: {eq: "ja-policy"}}) {
    ...PolicyFragment
  }
  zhPolicy: markdownRemark(frontmatter: {templateKey: {eq: "zh-policy"}}) {
    ...PolicyFragment
  }
 }
`;
